import Vue from "vue";
import VueRouter from "vue-router";
import protectedRoute from "./protectedRoute";

const HomePage = () => import("../components/routes/HomePage");
const ProjectDescriptionPage = () => import("../components/routes/ProjectDescriptionPage");
const InterfacePage = () => import("../components/routes/InterfacePage");
const AdminPage = () => import("../components/routes/AdminPage");
const NewsPage = () => import("../components/routes/NewsPage");
const NewsDetailPage = () => import("../components/routes/NewsDetailPage");
const Error404 = () => import("../components/routes/Error_404");
const CreateNewsPage = () => import("../components/routes/CreateNewsPage");

Vue.use(VueRouter);

const routes = [
    {path: '/', name:'HomePage', component: HomePage},
    {path: '/project-description', name:'ProjectDescriptionPage', component: ProjectDescriptionPage},
    {path: '/admin', name:'AdminPage', component: AdminPage},
    {path: '/news', name:'NewsPage', component: NewsPage},
    {path: '/news/:id', name:'NewsDetailPage', component: NewsDetailPage},
    {path: '/interface', name:'InterfacePage', component: InterfacePage},
    {path: '/error404', name:'Error404', component: Error404},
    {path: '/create-news', name:'CreateNewsPage', component: CreateNewsPage, beforeEnter: protectedRoute},
    {path: '*', redirect: '/error404', component: Error404}
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.HOSTNAME,
    routes: routes
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    next();
});

export default router;