<template>
 <div id="app">
    <NavbarLayout />
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import NavbarLayout from './components/layout/NavbarLayout.vue';

export default {
    name: "App",
    async mounted() {
      await this.$store.dispatch("CheckToken");
    },
    components: { NavbarLayout }
}
</script>
