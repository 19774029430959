import Vue from 'vue'
import App from './App.vue'
import router from "./plugins/router";
import Vue2Editor from "vue2-editor";
import VCalendar from "v-calendar";

import './style.css';

import axios from "axios";
import store from "./store/store";

Vue.config.productionTip = false

axios.defaults.withCredentials = true;

Vue.use(require("vue-cookies"));
Vue.use(Vue2Editor);
Vue.use(VCalendar, {componentPrefix: 'vc'});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
