<template>
    <header class="bg-neutral-800 box-shadow" v-click-outside="hide">
        <nav class="nav">
            <img :src="require('../../assets/Cubesat_Logo_White.png')" width="200" alt="Logo of a2pd accipiter">
            <span class="material-symbols-outlined nav-menu" @click="expended = !expended">menu</span>
            <ul role="list" class="nav-container fs-400" :data-expended="expended">
                <li class="nav-item" @click="expended = false">
                    <router-link to="/" class="nav-link" exact active-class="active">Home</router-link>
                </li>
                <li class="nav-item" @click="expended = false">
                    <router-link to="/project-description" class="nav-link" exact active-class="active">Project description</router-link>
                </li>
                <li class="nav-item" @click="expended = false">
                    <router-link to="/interface" class="nav-link" exact active-class="active">Interface</router-link>
                </li>
                <li class="nav-item" @click="expended = false">
                    <router-link to="/news" class="nav-link" exact active-class="active">News</router-link>
                </li>
            </ul>
        </nav>
    </header>
</template>

<style scoped>
.nav {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.nav-menu {
    display: none;
}
.nav > * {
    padding: .5rem;
}
.nav-container {
    padding: .5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.nav-item {
    display: block;
}
.nav-link {
    color: var(--clr-primary-300);
    text-decoration: none;
    padding: 1rem;
    background-color: var(--clr-neutral-900);
    border-radius: var(--border-radius);
    transition: background-color .25s ease-in-out;
}

.active {
    background-color: var(--clr-neutral-600);
    color: var(--clr-primary-300);
}

.nav-item:hover > .nav-link {
    background-color: var(--clr-neutral-700);
}

@media only screen and (max-width: 50em) {
    .nav-container[data-expended=true] {
        display: flex;
        flex-direction: column;
        width: 100vw;
        padding: 1rem;
    }
    .nav-container {
        display: none;
    }
    .nav-link {
        display: block;
        text-align: center;
    }
    .nav {
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .nav > img {
        width: 100px;
    }
    .nav-menu {
        display: block;
    }
}
</style>

<script>
    import ClickOutside from 'vue-click-outside'

    export default {
        name: "NavbarLayout",
        data: () => {
            return {
                expended: false,
            }
        },
        methods: {
            hide() {
                this.expended = false;
            }
        },
        directives: {
            ClickOutside
        }
    }
</script>