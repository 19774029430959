import axios from "axios";

const state = {
  news: [],
};

const getters = {
  getNewsBadge: (state) => state.news,
};

const actions = {
    async loadNewsBadge({ commit }, skip = 0) {
        try {
          let {data} = await axios.get("/api/news/badge?skip=" + skip);
          await commit("addNews", data);
        } catch(err) {
          await commit("setNews", []);
        }
    },
};

const mutations = {
  setNewsBadge(state, news) {
    state.news = news;
  },
  addNews(state, news) {
    if(!Array.isArray(state.news)) return;
    if(Array.isArray(news)) {
      state.news.push(...news);
    } else {
      state.news.push(news);
    }
    
  },
  removeNews (state, deletedNews) {
    state.news = state.news.filter(news => !deletedNews.find(el => el._id === news._id));
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
