import axios from "axios";
import Vue from "vue";

const state = {
  user: null,
  token: null,
};

const getters = {
  isAuthenticated: (state) => !!state.token || Vue.$cookies.get("isAuth") === "true",
  StateUser: (state) => state.user,
};

const actions = {
    async LogIn({ commit }, user) {
      Vue.$cookies.set("isAuth", "true");
      await commit("setUser", user);
      await commit("setToken", user.token);
    },

    async CheckToken({ commit }) {
        try {
          
          if(Vue.$cookies.get("isAuth") !== "true") throw new Error("Cookie invalid");
          
          const response = await axios.post("/api/authentication/check_token");
          if(response.data === null) throw Error("Not Auth");
          
          await commit("setUser", response.data);
          await commit("setToken", response.data.token);
        
        } catch {
            await commit("setUser", null);
            await commit("setToken", null);
            await commit("logout");
        }
    },

    async LogOut({ commit }) {
        Vue.$cookies.set("isAuth", null);
        await commit("logout", null);
    },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
  },
  logout() {
    Vue.$cookies.set("isAuth", null);
    Vue.$cookies.set("token", null);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
